<template>
  <!-- Created with Inkscape (http://www.inkscape.org/) -->
  <svg
    :width="width"
    :height="height"
    version="1.1"
    viewBox="0 0 41.492 9.2736"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  >
    <metadata>
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g transform="translate(-105.5 -166.88)">
      <g
        transform="matrix(.26458 0 0 .26458 62.046 148.81)"
        fill="#fff"
      >
        <path
          class="kheopsfont-st4"
          d="m172.65 87.66-4.68 0.15v15.01h-3.74v-34.06h3.74v15.75l4.63-0.2 8.22-15.56h4.23l-9.21 16.84 9.75 17.23h-4.23z"
        />
        <path
          class="kheopsfont-st4"
          d="m209.24 87.27h-15.41v15.56h-3.74v-34.07h3.74v15.21h15.41v-15.21h3.79v34.07h-3.79z"
        />
        <path
          class="kheopsfont-st4"
          d="m220.61 68.76h19.05v3.35h-15.31v11.72h12.55v3.35h-12.55v12.31h15.31v3.35h-19.05z"
        />
        <path
          class="kheopsfont-st4"
          d="m244 85.49c0-8.07 1.08-13.14 4.92-15.51 1.82-1.18 4.28-1.72 7.53-1.72 10.09 0 12.46 5.27 12.46 17.23 0 8.42-1.13 13.78-5.32 16.25-1.77 1.08-4.14 1.57-7.14 1.57-10.04 0.01-12.45-5.8-12.45-17.82zm16.88 13.64c3.5-1.72 4.09-6.1 4.09-13.64 0-9.94-1.03-13.88-8.52-13.88-1.77 0-3.2 0.2-4.33 0.69-3.59 1.53-4.18 5.61-4.18 13.19 0 9.95 1.03 14.47 8.52 14.47 1.86 0.01 3.29-0.24 4.42-0.83z"
        />
        <path
          class="kheopsfont-st4"
          d="m279.05 91.6v11.22h-3.74v-34.06h10.78c7.14 0 10.83 3.1 10.83 11.03 0 8.32-3.99 11.82-10.83 11.82h-7.04zm6.84-3.4c4.63 0 7.09-2.26 7.09-8.52 0-5.76-2.46-7.58-7.09-7.58h-6.84v16.1z"
        />
        <path
          class="kheopsfont-st4"
          d="m319.96 72.3s-5.42-0.69-8.71-0.69c-4.48 0-6.74 1.62-6.74 5.71 0 4.14 2.26 4.97 7.38 6.25 6.1 1.53 9.16 3.25 9.16 9.3 0 6.84-3.25 10.44-10.24 10.44-3.84 0-9.75-0.94-9.75-0.94l0.25-3.1s6.25 0.69 9.5 0.69c4.43 0 6.4-2.31 6.4-6.74 0-4.14-2.26-5.27-6.89-6.3-6.3-1.48-9.7-3.45-9.7-9.21 0-6.55 3.69-9.45 10.44-9.45 3.89 0 9.11 1.03 9.11 1.03z"
        />
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'KheopsFont',
  props: {
    width: {
      type: String,
      required: false,
      default: '41.492mm',
    },
    height: {
      type: String,
      required: false,
      default: '9.2736mm',
    },
  },
};
</script>
