<template>
  <svg
    id="svg4024"
    xmlns:osb="http://www.openswatchbook.org/uri/2009/osb"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    :width="width"
    :height="height"
    viewBox="0 0 50.245697 31.332766"
    version="1.1"
    sodipodi:docname="dessin.svg"
    inkscape:version="0.92.4 (unknown)"
  >
    <defs
      id="defs4018"
    >
      <linearGradient
        id="linearGradient6338"
        osb:paint="solid"
      >
        <stop
          id="stop6336"
          style="stop-color:#212b3d;stop-opacity:1;"
          offset="0"
        />
      </linearGradient>
    </defs>
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="0.98994949"
      inkscape:cx="-124.32618"
      inkscape:cy="-68.784688"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1920"
      inkscape:window-height="1025"
      inkscape:window-x="1920"
      inkscape:window-y="27"
      inkscape:window-maximized="1"
    />
    <metadata
      id="metadata4021"
    >
      <rdf:RDF>
        <cc:Work
          rdf:about=""
        >
          <dc:format>image/svg+xml</dc:format>
          <dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage"
          />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      id="layer1"
      inkscape:label="Calque 1"
      inkscape:groupmode="layer"
      transform="translate(-86.317918,-125.17429)"
    >
      <g
        id="g209"
        transform="matrix(0.35277777,0,0,-0.35277777,87.430589,154.39668)"
      >
        <path
          id="path211"
          d="M 0,0 H 116.221 L 58.111,66.904 Z M 58.111,61.512 108.466,3.535 H 7.755 Z"
          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          inkscape:connector-curvature="0"
        />
      </g>
      <g
        id="g213"
        transform="matrix(0.35277777,0,0,-0.35277777,120.11422,126.63903)"
      >
        <path
          id="path215"
          d="m 0,0 -22.178,-22.956 2.375,-2.734 19.66,20.353 33.916,-39.05 H -3.564 l 3.133,-3.607 h 42.115 z"
          style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          inkscape:connector-curvature="0"
        />
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'KheopsPyramid',
  props: {
    width: {
      type: String,
      required: false,
      default: '12.2696mm',
    },
    height: {
      type: String,
      required: false,
      default: '7.41195mm',
    },
  },
};
</script>
