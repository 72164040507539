<template>
  <svg
    id="svg2"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    version="1.1"
    inkscape:version="0.92.4 (f8dce91, 2019-08-02)"
    xml:space="preserve"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    sodipodi:docname="logo-button.svg"
    inkscape:export-filename="/home/nicolas/Data/Data/Projects/Weasis/Design and Graphics/Logo/logo-button-512.png"
    inkscape:export-xdpi="1536"
    inkscape:export-ydpi="1536"
  >
    <metadata
      id="metadata8"
    >
      <rdf:RDF>
        <cc:Work
          rdf:about=""
        >
          <dc:format>image/svg+xml</dc:format>
          <dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage"
          />
          <dc:title />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <defs
      id="defs6"
    >
      <clipPath
        id="clipPath16"
        clipPathUnits="userSpaceOnUse"
      >
        <path
          id="path18"
          d="M 0,0 256,0 256,256 0,256 0,0 Z"
          inkscape:connector-curvature="0"
        />
      </clipPath>
      <clipPath
        id="clipPath32"
        clipPathUnits="userSpaceOnUse"
      >
        <path
          id="path34"
          d="M 0,0 256,0 256,256 0,256 0,0 Z"
          inkscape:connector-curvature="0"
        />
      </clipPath>
    </defs>
    <sodipodi:namedview
      id="namedview4"
      pagecolor="#003866"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="1688"
      inkscape:window-height="810"
      showgrid="false"
      inkscape:zoom="3.1465617"
      inkscape:cx="53.678287"
      inkscape:cy="31.263124"
      inkscape:window-x="1347"
      inkscape:window-y="730"
      inkscape:window-maximized="0"
      inkscape:current-layer="g10"
      inkscape:showpageshadow="false"
      showborder="true"
    />
    <g
      id="g10"
      inkscape:groupmode="layer"
      inkscape:label="logo-button"
      transform="matrix(1.25,0,0,-1.25,-144.82125,170.56875)"
      @mouseover="hover = true;"
      @mouseout="hover = false;"
    >
      <g
        id="g12"
        style="fill:#ffffff"
        transform="matrix(0.43056445,0,0,0.43056445,65.627046,62.915596)"
      >
        <g
          id="g14"
          clip-path="url(#clipPath16)"
          style="fill:#ffffff"
        >
          <g
            id="g20"
            transform="translate(120.6064,141.0825)"
            style="fill:#ffffff"
          >
            <path
              id="path22"
              d="M 0,0 C 0,13.939 11.296,25.238 25.227,25.238 39.158,25.238 50.454,13.939 50.454,0 50.454,-13.938 39.158,-25.237 25.227,-25.237 11.296,-25.237 0,-13.938 0,0"
              inkscape:connector-curvature="0"
              style="fill-opacity:1;fill-rule:nonzero;stroke:none"
              :style="hover ? 'fill: #fca638' : 'fill: #ffffff'"
            />
          </g>
        </g>
      </g>
      <g
        id="g24"
        transform="matrix(0.43056445,0,0,0.43056445,128.74719,117.76654)"
      >
        <path
          id="path26"
          d="m 0,0 8.326,16.613 -16.648,0 L 0,0 Z"
          style="fill:#003866;fill-opacity:1;fill-rule:nonzero;stroke:none"
          inkscape:connector-curvature="0"
        />
      </g>
      <g
        id="g28"
        transform="matrix(0.43056445,0,0,0.43056445,65.627046,62.915596)"
      >
        <g
          id="g30"
          clip-path="url(#clipPath32)"
        >
          <g
            id="g36"
            transform="translate(146.4863,137.6294)"
          >
            <path
              id="path38"
              d="M 0,0 C 1.285,0 2.336,1.074 2.336,2.389 2.336,3.695 1.312,4.725 0,4.725 -1.313,4.725 -2.395,3.674 -2.395,2.389 -2.395,1.074 -1.313,0 0,0"
              style="fill:#003866;fill-opacity:1;fill-rule:nonzero;stroke:none"
              inkscape:connector-curvature="0"
            />
          </g>
          <g
            id="g40"
            transform="translate(130.7539,151.9614)"
          >
            <path
              id="path42"
              d="m 0,0 14.255,-28.514 c 0.283,-0.557 0.852,-0.908 1.476,-0.908 0.623,0 1.194,0.351 1.482,0.908 L 31.468,0 c 0.25,0.51 0.219,1.113 -0.08,1.602 -0.299,0.482 -0.825,0.783 -1.403,0.783 l -0.509,0 c -0.623,0 -1.202,-0.354 -1.477,-0.91 L 15.731,-23.014 3.464,1.475 c -0.276,0.556 -0.843,0.91 -1.47,0.91 l -0.515,0 C 0.905,2.385 0.376,2.084 0.079,1.602 -0.222,1.113 -0.247,0.51 0,0"
              style="fill:#003866;fill-opacity:1;fill-rule:nonzero;stroke:none"
              inkscape:connector-curvature="0"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'WeasisIcon',
  props: {
    width: {
      type: String,
      required: false,
      default: '32',
    },
    height: {
      type: String,
      required: false,
      default: '32',
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
