<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    :height="height"
    viewBox="0 0 289 224"
    enable-background="new 0 0 289 224"
    xml:space="preserve"
    class="kheopsicon"
  >
    <path
      id="icon_interior"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M145.554,7.87l55.875,84.919
        c5.886,4.24,10.558,7.768,14.646,10.853c13.508,10.198,20.365,15.378,32.667,17.625c4.067,0.744,7.905,0.78,13.978,0.836
        c2.405,0.022,5.134,0.05,8.154,0.114c4.092,0.079,7.343,3.461,7.263,7.553c-0.065,3.42-2.442,6.255-5.61,7.045l0.004,0.015
        c-4.22,1.06-9.158,1.963-14.181,2.491c-4.508,0.475-9.061,0.645-13.196,0.361c-4.865-0.336-10.084-1.764-15.415-3.87l54.116,82.246
        H145.554H7.254L53.286,148.1c-1.084,0.354-2.197,0.706-3.343,1.059l-0.409,0.112l0.001,0.004c-3.737,0.917-7.505,0.227-10.571-1.631
        c-3.069-1.859-5.427-4.876-6.344-8.609c-0.036-0.146-0.067-0.292-0.094-0.438c-0.772-3.609-0.077-7.206,1.697-10.136
        c0.157-0.262,0.327-0.508,0.51-0.742c1.877-2.704,4.7-4.756,8.126-5.6l0.03-0.007c7.806-2.188,13.507-4.364,18.349-7.452
        c4.844-3.089,9.161-7.313,14.259-13.631c6.592-8.167,13.853-14.614,21.578-19.481L145.554,7.87L145.554,7.87z M117.656,90.552
        c6.434-2.223,14.04-3.129,24.045-3.05c16.226,0.128,33.014,6.509,48.173,16.318c14.604,9.448,38.68,27.274,55.772,28.453
        c7.922,0.546,17.615-0.77,25.082-2.648c-11.682-0.251-16.969,0.097-23.322-1.066c-18.749-3.428-25.518-12.12-53.152-31.787
        c-36.927-26.282-82.575-28.758-112.974,8.905c-11.208,13.889-19.409,18.841-36.648,23.646c-3.52,0.862-5.672,4.416-4.808,7.939
        c0.865,3.519,4.419,5.672,7.939,4.809c9.237-2.84,16.307-5.69,22.473-9.532c0,0.226,0.01,0.451,0.035,0.678
        c-2.931,10.117-7.046,28.748-28.9,47.118c-2.829,2.269-3.278,6.399-1.01,9.227c2.27,2.824,6.4,3.278,9.228,1.008
        c14.52-13.032,23.545-26.258,28.656-43.378c3.622,4.611,7.82,8.097,14.512,12.613c29.254,19.742,101.869,39.341,133.071,18.769
        c26.59-17.529-10.003-50.238-21.269-26.426c-7.632,16.128,20.503,21.628,18.534,6.862c-0.293-2.2-1.169-3.514-3.241-4.197
        l-0.003,0.001c0.435,0.815,1.004,1.71,1.23,2.589c2.758,10.709-16.078,8.098-12.827-1.537c5.706-16.91,31.956,5.172,13.442,16.174
        c-23.708,14.091-75.61,0.554-99.18-9.771c-12.644-5.539-25.101-12.162-33.641-22.688c35.4,6.891,89.006,7.669,117.999-3.411
        l8.302-3.939l-9.004,1.271c-38.811,5.903-79.421,5.389-117.642-4.436c-1.87-0.48-6.077-2.242-9.699-3.03
        c4.006-3.625,7.732-8.113,12.055-13.512c3.107-3.882,5.991-7.233,8.84-10.11c-0.877,2.181-1.362,4.563-1.362,7.058
        c0,10.452,8.472,18.924,18.924,18.924s18.924-8.472,18.924-18.924C136.21,99.144,127.937,90.75,117.656,90.552z"
    />
  </svg>
</template>

<script>

export default {
  name: 'OsirixIcon',
  components: { },
  props: {
    width: {
      type: String,
      required: true,
      default: '0px',
    },
    height: {
      type: String,
      required: true,
      default: '0px',
    },
  },
  data() {
    return { };
  },
};
</script>
