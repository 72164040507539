<template>
  <span>
    An error occurred...
  </span>
</template>
<script>

export default {
  name: 'OidcCallbackError',
};
</script>
